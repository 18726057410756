
import axios from 'axios';
import router from '../router/index';
import qs from "qs";
import { Message } from 'element-ui';
import { getStore, setStore } from './storage';
import { getLanguage,setLanguage } from "../lang";


let timer = 0;
const instance = axios.create({
    timeout: 100 * 1000, // 请求时间超过100s就报超时错误（来自axios）
})



// 请求拦截器
instance.interceptors.request.use(config => {
    // 拦截开放平台端的接口
    if (/\/star\/open/.test(config.url)) {
        config.url =  config.url.replace('/star/open','/open')
    }
    heardJson.accessToken = getStore("accessToken") || ""
    config.headers = getHeaders();
    return config;
}, error => {
    return Promise.reject(error)
});
// 响应拦截器
instance.interceptors.response.use(async res => {
    if (res.status === 200) {
        if (res.data.code === 200) {
            res.data.successed = true;
            return res.data
        } else {
            errorHandle(res)
        }
    } else {
        errorHandle(res)
    }
    return res.data
}, async error => {
    const config = error.config;
    const response = error.response;
    if (response.data.code === 401) {
        // 上面的刷新token代码 
    } else {
        tipsErr(response.statusText || response.status || '')
        // errorHandle(); //其他错误进入错误处理
    }
    return Promise.reject(error.response)
});










function getHeaders() {
    return {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept-Info': JSON.stringify(heardJson),
        'Accept-Language': getLanguage(),
        'timezone': getTimezone()
    }
}

// appmanager: ALLIEDSTAR, NEOSS
export let heardJson = {
    "app_code": "support",
    "p_version": "1.0.0",
    "c_version": "1.0.0",
    "appmanager": getAppManager()
}

// 获取时区
function getTimezone() {
    let timeZone = ''
    try {
        timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
    } catch (error) {
        console.log(error)
    }
    return timeZone
}

// getJSON
export function getJSON(url, params) {
    return axios.get(url, params)
}

// headReason
export const acceptInfo = {
    ...heardJson,
    "operationSystem":winBrowserInfo.operationSystem,
    accessToken: getStore('accessToken') || ""
}



export function getAcceptInfo () {
    return {
        ...heardJson,
        "operationSystem":winBrowserInfo.operationSystem,
        accessToken: getStore('accessToken') || ""
    }
}

function getAppManager() {
    let str = "";
    try {
        // 获取二级域名前缀 注：- 之前的内容
        str = location.host.replace(/(\.|\-)(.*)/, "") + ""
        // str = APP_MANAGER_MAP[hostBefore];
    } catch (error) {
        console.log(error)
    }
    return "ALLIEDSTAR"
    // return APP_MANAGER_MAP[str] || "ALLIEDSTAR"
    // return "NEOSS"
}


//错误处理函数,
function errorHandle(res) {
    // 与后台约定402为登录过期
    if (res.data.code == 402) logout(res)
    else tipsErr(`${res.data.msg}`)
}
async function logout(res) {
    res.data && tipsErr(res.data.msg);
    await store.dispatch("user/logout");
    router.replace(`/login?redirect=${router.history.current.fullPath}`);
}
function tipsErr(msg) {
    let curtimer = new Date().valueOf();
    if (curtimer - timer > 1000) {
        msg && Message.error(msg);
    }
    timer = curtimer;
}

export const post = (url,params) => {
    return instance({
        url: url,
        method: "post",
        data: qs.stringify(params)
    })
}
export const getRequest = (url,params) => {
    return instance({
        url: url,
        method: "get",
        params: params
    })
}
