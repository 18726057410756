<template>
  <div class="support-download-page">
    <myHeader v-if="showFlag"></myHeader>
    <div id="iframeMain" class="download_wrap app_container">
      <div class="banner_wrap bg-cover">
        <p class="banner-title">{{$t('route.rDownload')}}</p>
      </div>
      <!-- <h1 class="title">{{$t('route.rDownload')}}</h1> -->
      <div class="page_width download_page">
        <el-row class="card_wrap" type="flex">
          <el-col class="card card1">
            <div class="title">{{$t('download.DocDownload')}}</div>
            <el-form label-position="top" :model="formData" ref="ruleForm" :rules="rules">
              <el-form-item :label="$t('download.selProdModel')" prop="model">
                <el-select popper-class="select_prod_popper" size="mini" v-model="formData.model" style="width: 100%" :placeholder="$t('tip.pleaseChoose')">
                  <el-option size="mini" v-for="(item, i) in docDataList" :key="i" :label="item.equipmentModel" :value="item.equipmentModel" @click.native="changeDocModel(item)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('download.selDocType')" prop="docType">
                <el-select @change="formData.lang = ''" :disabled="!formData.model" popper-class="select_prod_popper" size="mini" v-model="formData.docType" style="width: 100%"
                  :placeholder="$t('tip.pleaseChoose')">
                  <el-option size="mini" v-for="(item, i) in targetDocTypeList" :key="i" :label="item.documentTypeName" :value="item.documentTypeCode" @click.native="changeDocType(item)"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item :label="$t('download.selDocLang')" prop="langDocLink">
                <el-select :disabled="!formData.docType" popper-class="select_prod_popper" size="mini" v-model="formData.langDocLink" style="width: 100%" :placeholder="$t('tip.pleaseChoose')">
                  <el-option size="mini" v-for="(item, i) in docLinkList" :key="i" :label="item.language" :value="item.downloadLink"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <div class="btn" @click="downDoc()"> <img class="down_icon" src="../assets/images/download/down_icon.png" alt=""> {{$t("download.now_download")}}</div>
          </el-col>
          <el-col class="card card2">
            <div class="title">{{$t('download.softDownload')}}</div>
            <div class="slogn">{{$t('download.solftDownSlogn')}}</div>
            <img class="soft_icon" src="../assets/images/download/pc.png" srcset="">
            <div class="btn" @click="downSoft('ASSolution')"> <img class="down_icon" src="../assets/images/download/down_icon.png" alt="">{{$t('download.now_download')}}</div>
          </el-col>
          <el-col class="card card3">
            <div class="title">{{$t('download.toolDownload')}}</div>
            <div class="slogn">{{$t('download.teamwSlogn')}}</div>
            <img class="soft_icon" src="../assets/images/download/teamwear.jpg" srcset="">
            <div class="btn" @click="downTeamWear()"> <img class="down_icon" src="../assets/images/download/down_icon.png" alt="">{{$t('download.now_download')}}</div>
          </el-col>
          <!-- <div class="previous" @click="dialogVisible=true">{{$t('download.previous_version')}}</div> -->
        </el-row>
      </div>
    </div>
    <myFooter v-if="showFlag"></myFooter>
    <!-- <el-dialog :visible.sync="dialogVisible" custom-class="dialog-wrap" top="0px">
      <div class="dialog-container">
        <img src="../assets/images/download/dialog_png.png" class="dialog-img" alt="">
        <p class="dialog-text">{{$t('download.scanproSlogn')}}</p>
      </div>
      <div slot="footer" class="dialog-footer">
        <div class="dialog-btn" @click="downSoft('SCANPRO')">
          <img src="../assets/images/download/blue_arrow.png" alt="">
          <span>ScanPro</span>
        </div>
        <div class="dialog-btn dialog-btn-right" @click="downSoft('ASSolution')">
          <img src="../assets/images/download/white_arrow.png" alt="">
          <span>AS Connect</span>
        </div>
      </div>
    </el-dialog> -->
  </div>
</template>

<script>
import { listDocumentDownload, softwareGetDownloadUrl } from "../api"
import myHeader from "./components/myHeader.vue";
import myFooter from './components/myFooter.vue'
import { getLanguage } from "../lang";
import {getUrlParams4} from '../utils'
const langMap = {
  'zh-CN':{
    "zh_CN": "中文",
    "en": "English",
    "no":"norsk",
    "fr":"Français",
    "es":"Español",
    "da":"Dansk",
    "nl":"Nederlands",
    "de": "Deutsch",
    'combination_one':"英语/丹麦语/德语/西班牙语/法语/意大利语/荷兰语/挪威语/瑞典语",
    'combination_two':"英语/德语/西班牙语/法语/意大利语",
    'combination_three':"英语/丹麦语/德语/西班牙语/法语/意大利语/荷兰语/挪威语/瑞典语/日语"
  },
  en:{
    "zh_CN": "Chinese",
    "en": "English",
    "no":"norsk",
    "fr":"Français",
    "es":"Español",
    "da":"Dansk",
    "nl":"Nederlands",
    "de": "Deutsch",
    'combination_one':"EN/DA/DE/ES/FR/IT/NL/NO/SV",
    'combination_two':"EN/DE/ES/FR/IT",
    'combination_three':"EN/DA/DE/ES/FR/IT/NL/NO/SV/JP"
  },
}
export default {
  components: { myHeader,myFooter },
  data() {
    return {
      showModal: false,
      searchVal: "",
      docDataList: [],
      docTypeList:[],
      docLinkList: [],
      // dialogVisible:false,
      formData: {
        model: undefined,
        docType: undefined,
        langDocLink: undefined,
      },

      rules: {
        model: [{ required: true, message: " ", trigger: "change" }],
        docType: [{ required: true, message: " ", trigger: "change" }],
        langDocLink: [{ required: true, message: " ", trigger: "change" }]
      },
      showFlag:true,
    }
  },
  computed:{
    targetDocTypeList(){
      return this.docTypeList.filter(item => item.documentTypeName) || [];
    },
    language(){
      return getLanguage();
    }
  },
  async created() {
    let pageLoading = this.$pageLoading();
    this.docDataList = await this.getListDocumentDownload();
    pageLoading.close();
    this.initPage();
  },
  mounted(){
    let iframeMainHeight = Math.ceil(document.getElementById("iframeMain")?.scrollHeight * 1);
    window.parent.postMessage({type:'supportDown',data:iframeMainHeight},'*');
  },
  methods: {
    initPage(){
      let paramRes = getUrlParams4(location.href).result;
      if(paramRes && paramRes.hasOwnProperty('type') && paramRes['type'] == 1){
        this.showFlag = false;
      }else{
        this.showFlag = true;
      }
    },
    getListDocumentDownload() {
      return new Promise(async (resolve, reject) => {
        listDocumentDownload().then((res) => {
          if (res.successed) {
            resolve(res.data || []);
          } else {
            reject(res)
          }
        }).catch((err) => {
          reject(err)
        });
      })
    },
    changeDocModel(item = {}) {
      this.docTypeList = item.documentInfoDTOList || [];
      this.formData.docType = (this.targetDocTypeList && this.targetDocTypeList.length===1)?this.targetDocTypeList[0].documentTypeCode:undefined;
      if(this.formData.docType){
        this.changeDocType(this.targetDocTypeList[0])
      }
    },
    changeDocType(item = {}) {
      this.formData.langDocLink = undefined
      this.docLinkList = (item.downLoadLinkDTOList || []).map((_item = {}) => {
        return {
          ..._item,
          language: langMap[this.language][_item.language]
        }
      });
    },

    downDoc() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) return
        window.open(this.formData.langDocLink)
      })
    },
    downSoft(type) {
      let softwareType = type || '';
      softwareGetDownloadUrl({softwareType}).then((res) => {
        if (res.code === 200) {
          let {data} = res;
          if(data.indexOf('images.allied-star.com') != -1){
            data = data.replace('images.allied-star.com','images-accelerate.allied-star.com')
          }
            window.open(data);
        }
      });
    },
    // 临时
    downASCSoft(){
      window.open("https://images-accelerate.allied-star.com/scanpro/install_package/2023042616447-2/AS-SolutionSuite_1.0.0.1.exe")
    },
    downTeamWear() {
      window.open('https://images-accelerate.allied-star.com/static/alliedstar/download/Alliedstar_Support.exe')
    },
    
  }
};
</script>

<style lang="scss" scoped>
@import "../assets/style/variables.scss";
.page-scroll-auto{
  overflow: auto;
}
.page-scroll-hidden{
  overflow: hidden;
}
.support-download-page{
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.download_wrap {
  flex:1;
  .download_page{
    width: 78%;
    min-width: 1200px;
    margin-top: 56px;
  }
  .banner_wrap {
    height: 300px;
    background-color: #999;
    background-image: url("../assets/images/download/download_banner.png");
    position: relative;
    .banner-title{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      font-size: 48px;
      font-weight: 700;
      color: #fff;
    }
  }
  h1.title {
    margin: 40px auto 30px;
    font-size: 24px;
    color: #333333;
    letter-spacing: 2.5px;
    font-weight: 600;
    text-align: center;
  }
  .card_wrap {
    margin-bottom: 50px;
    padding: 35px 0;
    background: #ffffff;
    box-shadow: 5px 5px 20px 0 rgba(0, 89, 118, 0.1);
    border-radius: 6px;
    position: relative;
    .previous{
      font-size: 14px;
      font-weight: 350;
      color: #5183DB;
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 10px;
      cursor: pointer;
    }
    .card {
      position: relative;
      padding: 0 50px 30px;
      border-right: 1px solid #ddd;
      .title {
        margin-bottom: 30px;
        text-align: center;
        font-size: 18px;
        color: #000000;
        letter-spacing: 1px;
        font-weight: 500;
      }
      .slogn {
        width: 78%;
        position: absolute;
        font-size: 12px;
        text-align: center;
        color: #666666;
        letter-spacing: 0.75px;
        font-weight: 400;
        line-height: 1.5;
      }
    }
    .card1 {
      ::v-deep .el-form {
        padding-bottom: 20px;
        .el-form-item {
          margin-bottom: 21px;
          text-align: left;
          .el-form-item__label {
            padding-bottom: 0;
            line-height: 1.5;
            font-size: 14px;
            color: #666666;
            font-weight: 400;
          }
        }
      }
      .btn{
        left: 50%;
      }
    }
    .card2 {
      padding-left: 25px;
      padding-right: 25px;
      .slogn{
        width: 92%;
        word-break: break-word;
      }
      .soft_icon {
        width: 330px;
        display: block;
        margin: 100px auto 0px;
      }
      .btn-wrap {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: wrap;
        // .btn {
        //   display: flex;
        //   justify-content: center;
        //   position: relative;
        //   left: auto;
        //   padding-left: 10px;
        //   padding-right: 10px;
        //   text-align: center;
        //   min-width: 125px;
        //   transform: translateX(0);
        //   margin: 0 10px;
        // }
      }
    }
    .card3 {
      border-right: none;
      .soft_icon {
        display: block;
        margin: 120px auto 10px;
        width: 250px;
      }
      .btn{
        left: 50%;
      }
    }
    .btn {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      padding: 8px 40px;
      background: $primary;
      border-radius: 2px;
      font-size: 14px;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
      cursor: pointer;
      .down_icon {
        margin-right: 5px;
        width: 13px;
        height: 12px;
        vertical-align: middle;
      }
    }
    // .down_scanpro {
    //   // width: 120px;
    //   height: 35px;
    //   left: 0;
    //   transform: translateX(0);
    //   margin-left: 50px;
    //   padding: 0px 0px;
    //   justify-content: center;
    // }
    // .down_asconnect {
    //   // width: 120px;
    //   height: 35px;
    //   right: 0;
    //   transform: translateX(0);
    //   margin-right: 50px;
    //   padding: 0px 0px;
    //   justify-content: center;
    // }
  }
}

#down-modal-main {
  .content {
    padding: 15px;
    max-height: 65vh;
    overflow-y: auto;
    .search-wrap {
      display: flex;
      overflow: hidden;
      .search-input {
        // flex: 1;
        flex: 1;
        display: inline-block;
        // float: left;
        // width: 60%;
      }
      .search-btn {
        margin-left: 10px;
        width: 80px;
        line-height: 38px;
        text-align: center;
        display: inline-block;
        background: #5183db;
        border-radius: 3px;
        color: #fff;
        cursor: pointer;
      }
    }
    .desc {
      .title {
        margin: 20px 0 26px;
        font-size: 18px;
        color: #000000;
        font-weight: 400;
      }
      .step {
        margin: 20px auto;
        font-size: 16px;
        color: #000000;
        font-weight: 400;
        text-align: center;
        .step-title {
          text-align: left;
        }
        img {
          margin: 10px auto;
          width: 80%;
        }
      }
    }
  }
}
::v-deep .el-dialog{
  width: 760px;
  height: 490px;
  position: absolute;
  margin-bottom: 0;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  .el-dialog__body{
    padding: 0;
  }
  .el-dialog__footer{
    padding: 0;
  }
}
.dialog-wrap{
  .dialog-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .dialog-img{
      width: 443px;
      height: auto;
    }
    .dialog-text{
      font-size: 16px;
      line-height: 28px;
      font-weight: 350;
      text-align: center;
      width: 81%;
      word-break: normal;
      margin-top: 28px;
      margin-bottom: 31px;
    }
  }
  .dialog-footer{
    display: flex;
    justify-content: center;
    align-items: center;
    .dialog-btn{
      width: 168px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      border: 1px solid #5183DB;
      background: #FFF;
      margin: 0 14px;
      cursor: pointer;
      img{
        width: 16px;
        height: 16px;
        margin-right: 10px;
      }
      span{
        font-size: 14px;
        font-weight: 700;
        color: #5183DB;
      }
    }
    .dialog-btn-right{
      background: #5183DB;
      span{
        color: #fff;
      }
    }
  }
}
</style>