<template>
  <div class="footer_wrap">
    <div class="page_width">
      <a href="https://beian.miit.gov.cn/" target="_blank">{{$t('common.internetContentProvider')}}</a>
      <span style="margin-left: 10px">{{$t('common.copyright')}}</span>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer_wrap {
  display: flex;
  align-items: center;
  min-height: 50px;
  // line-height: 1.5;
  text-align: center;
  color: #ddd;
  font-size: 12px;
  // overflow: hidden;
  // padding-top: 50px;
  background-color: #999;
}
</style>