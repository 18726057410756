<template>
  <div class="nav_wrap">
    <div class="fixed_wrap">
      <div class="nav_main">
        <div class="logo_wrap" @click="toIndex">
          <img class="logo" :src="logo" alt="" />
        </div>
        <span class="nav_left">
          <el-dropdown trigger="click" @command="handleSetLanguage">
            <span class="el-dropdown-link">
              {{ arrayToMapVal($dict.langs)[language] }}
              <i class="el-icon-arrow-down"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in $dict.langs"
                :key="index"
                :command="item.value"
                :disabled="language == item.value"
                >{{ item.text }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import logoImg from "../../assets/images/logo.png";
import { arrayToMapVal } from "../../utils/commonUtil";
// import { getLanguage,setLanguage } from "../../lang";
import Vue from "vue"
import Dict from '../../utils/dict'
export default {
  inject: ["reload"], //刷新 比reload()好用
  computed: {
    logo() {
      return logoImg;
    },
    language(){
      return this.$store.getters.language;
    }
  },
  data() {
    return {};
  },
  methods: {
    toIndex() {
      window.location.href = `https://support.allied-star.com?lang=${this.language}`;
    },
    arrayToMapVal,
    async handleSetLanguage(lang) {
      let pageLoading = this.$pageLoading();
      try {
        await this.$store.dispatch('app/setLanguage',lang);
        this.$i18n.locale = lang;
        Vue.prototype.$dict = new Dict();
      } catch (error) {
        console.error(error)
      }
      pageLoading.close();
      this.reload();
      this.$emit("on-change", lang);
    },
  },
};
</script>

<style lang="scss" scoped>
.logo_wrap {
  display: inline-block;
  height: 50%;
  cursor: pointer;
  .logo {
    height: 100%;
    vertical-align: middle;
  }
  .logo_text {
    margin-left: 10px;
    font-size: 16px;
    color: #333333;
    letter-spacing: 0.67px;
    font-weight: 500;
  }
}
.el-dropdown {
  padding-left: 10px;
  // padding-bottom: 2px;
  display: flex;
  align-items: center;
  font-size: 14px;
}
</style>