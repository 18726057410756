import { post } from '../utils/request';

const host_url = process.env.VUE_APP_BASE_API;
const help_center_baseUrl = host_url + '/star';


/* 文档下载列表 */
export const listDocumentDownload = (params) => {
  return post(help_center_baseUrl + '/help_center/download/listDocumentDownload', params)
}
/* getDownloadUrl */
export const softwareGetDownloadUrl = (params) => {
  return post('https://api.allied-star.com/star' + '/v2/software/getDownloadUrl', params)
}

